@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/fonts-2024';
@import 'styles/layout-tokens';
@import 'styles/colors-2024';

.login_header {
	text-align: center;

	h1 {
		margin-top: 0.75rem;
		max-width: 18ch;
		margin-inline: auto;
	}

	p {
		max-width: 30ch;
		margin-inline: auto;
	}

	margin-bottom: 1.5rem;
}

.inputs {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	margin-bottom: 2rem;

	@include xs-only {
		max-height: 270px;
		flex: 1;
	}
}

.login_modal > div {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
	grid-area: center;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 100%;
}

.login_modal > div[data-visible='true'] {
	opacity: 1;
	visibility: visible;
}

.login_modal {
	border-radius: $spacing-xx-small-desktop;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(50px);
	min-height: 320px;

	padding: 56px 76px;

	display: grid;

	grid-template-areas: 'center';

	height: 100%;
	max-height: 100vh;

	@include sm-down {
		padding: 2.5rem 1.5rem 2rem;
		background: transparent;
		backdrop-filter: none;
	}

	@include xs-only {
		width: 100%;
		padding: 0;
	}
}

.form {
	display: flex;

	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: 2;

	width: 388px;

	@include xs-only {
		width: 100%;
		height: 100%;
	}

	label {
		width: 100%;
		position: relative;
	}

	input {
		border-radius: 4px;
		border: none;
		outline: 1px solid #fff;
		width: 100%;
		display: flex;
		height: 54px;
		padding: 12px 16px;
		justify-content: center;
		align-items: center;
		gap: 16px;
		align-self: stretch;
		background-color: transparent;

		color: white;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
		letter-spacing: 0.16px;

		&::placeholder {
			color: #fff;
		}

		&:focus {
			outline-color: #aaa;

			&::placeholder {
				color: #aaa;
			}
		}
	}

	.cta_button,
	.empty_button {
		width: 100%;
		border-radius: 8px;
		background: white;
		backdrop-filter: blur(12px);
		display: flex;
		padding: 16px 32px;
		justify-content: center;
		align-items: center;
		gap: 6px;
		align-self: stretch;
		font-size: 16px;
	}

	.error {
		color: $error-red-dark-mode;
		background: rgba(50, 0, 0, 0.24);
		padding: 0.5rem 1rem;
		border-radius: 0.5rem;
	}

	.empty_button {
		background: transparent;
		backdrop-filter: none;
		color: white;
	}

	.message {
		color: #dbdbdb;
		font-size: 14px;
		text-align: center;
		margin-top: -10px;
	}
}

.password_visibility_toggle {
	position: absolute;
	right: 1rem;
	top: 0;
	bottom: 0;

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $background-black;
	color: $text-white;

	display: flex;
	justify-content: center;
	align-items: center;

	isolation: isolate;

	height: 100%;
}

.container nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 4rem;

	display: flex;
	justify-content: flex-start;
	align-items: center;

	max-width: 1440px;

	margin-inline: auto;

	a {
		max-width: 90px;
	}

	@include sm-down {
		padding: $spacing-medium-mobile;

		a {
			max-width: 60px;
		}
	}
}

.image {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: -1;

	img {
		height: 100%;
		width: 100%;

		@include sm-down {
			filter: brightness(0.6);
		}
	}
}

.login_container {
	@include xs-only {
		height: 100%;
	}
	@include section-padding;
}

.back_button {
	color: $text-white;
	position: absolute;
	top: 0;
	left: 0;

	margin: 2rem;

	@include sm-down {
		top: 4rem;
	}
}
