@import './mixins/media-queries';

$nav-height-mobile: 85px;
$nav-height-desktop: 70px;

$max-width: 90rem;

/**
 * Spacings
 */

$spacing-xs: 0.5rem;
$spacing-sm: 1rem;
$spacing-md: 2rem;
$spacing-lg: 4rem;
$spacing-xl: 6.5rem;
$spacing-xxl: 10rem;

/**
 * Border Radius
 */

$br-xs: 4px;
$br-sm: 8px;
$br-sm-md: 12px;
$br-md: 16px;
$br-lg: 20px;
$br-xl: 24px;
$br-pill: 9999px;
$br-circle: 50%;

/**
 * Spacings New
 */
$spacing-xxx-small-desktop: 0.25rem;
$spacing-xx-small-desktop: 0.5rem;
$spacing-x-small-desktop: 0.75rem;
$spacing-small-desktop: 1rem;
$spacing-medium-desktop: 1.25rem;
$spacing-large-desktop: 2rem;
$spacing-x-large-desktop: 2.5rem;
$spacing-xx-large-desktop: 4rem;
$spacing-xxx-large-desktop: 7rem;

$spacing-xxx-small-mobile: 0.25rem;
$spacing-xx-small-mobile: 0.5rem;
$spacing-x-small-mobile: 0.75rem;
$spacing-small-mobile: 1rem;
$spacing-medium-mobile: 1.25rem;
$spacing-large-mobile: 1.5rem;
$spacing-x-large-mobile: 2rem;
$spacing-xx-large-mobile: 2rem;
$spacing-xxx-large-mobile: 4rem;

@mixin section-padding {
	padding: $spacing-xxx-large-desktop $spacing-xx-large-desktop;

	@include sm-down {
		padding: $spacing-xxx-large-mobile $spacing-medium-mobile;
	}
}

@mixin section-padding-inverse-x {
	margin-inline: -$spacing-xxx-large-desktop;

	@include sm-down {
		margin-inline: -$spacing-medium-mobile;
	}
}

@mixin section-padding-y {
	padding: $spacing-xxx-large-desktop 0;

	@include sm-down {
		padding: $spacing-xxx-large-mobile 0;
	}
}

@mixin section-padding-shorter {
	padding: $spacing-xx-large-desktop;

	@include sm-down {
		padding: $spacing-xxx-large-mobile $spacing-medium-mobile;
	}
}

@mixin section-padding-small {
	padding: $spacing-xx-large-desktop $spacing-x-large-desktop;

	@include sm-down {
		padding: $spacing-medium-mobile;
	}
}

@mixin max-width-center {
	max-width: $max-width;
	margin-inline: auto;
}

@mixin scale-eoy {
	@media (max-width: 23rem) {
		scale: 0.8;
	}

	@media (max-height: 860px) {
		scale: 0.9;
	}

	@media (max-height: 800px) {
		scale: 0.8;
	}

	@media (max-height: 700px) {
		scale: 0.75;
	}
}

$promo-height: 42px;
$promo-transition-time: 0.5s;
$promo-transition-ease: cubic-bezier(0.65, 0, 0.35, 1);

@mixin resize-test {
	resize: both;
	overflow: auto;
}
