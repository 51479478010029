$white: #ffffff;
$black: #000000;

/**
 * Primitive Colors
 */

// Brand
$brand-eight-blue: #1862ff;
$brand-royal-10: rgba(18, 97, 224, 0.1);
$brand-royal-30: rgba(18, 97, 224, 0.3);
$brand-royal-60: rgba(18, 97, 224, 0.6);
$brand-royal-blue: #1361e1;
$brand-royal-140: #0b3984;

$brand-accent-orange: #fe5c02;
$brand-accent-orange-10: rgba(254, 92, 2, 0.1);
$brand-accent-orange-30: rgba(254, 92, 2, 0.3);

// System
$success-green-light-mode: #027a48;
$success-green-dark-mode: #02ac65;
$success-green-10: #ecfdf3;
$error-red-light-mode: #b42318;
$error-red-dark-mode: #e02c1f;
$error-red-10: #fef3f2;

// Neutral
$neutral-black: #000000;

$neutral-subdued: #121212;
$neutral-subdued-2: #262626;
$neutral-subdued-3: #363636;

$neutral-grey-4: #636363;
$neutral-grey-3: #a0a0a0;
$neutral-grey-2: #d3d3d3;
$neutral-grey-1: #f1f2f4;

$off-white: #f9f8f7;

$neutral-white: #ffffff;

/**
 * Semantic Colors
 */

// Background
$background-white: $neutral-white;
$background-grey-light: $neutral-grey-1;
$background-subdued: $neutral-subdued;
$background-black: $neutral-black;
$background-success-green: $success-green-10;
$background-error-red: $error-red-10;
$background-accent-blue-10: $brand-royal-10;
$background-accent-blue-30: $brand-royal-30;
$background-accent-orange-10: $brand-accent-orange-10;
$background-accent-orange-30: $brand-accent-orange-30;
$background-off-white: $off-white;

// Border
$border-black: $neutral-black;
$border-grey-dark: $neutral-grey-4;
$border-grey-light: $neutral-grey-3;
$border-white: $neutral-white;
$border-success-green-light-mode: $success-green-light-mode;
$border-success-green-dark-mode: $success-green-dark-mode;
$border-error-red-light-mode: $error-red-light-mode;
$border-error-red-dark-mode: $error-red-dark-mode;
$border-accent-orange: $brand-accent-orange;
$border-accent-blue-light-mode: $brand-eight-blue;
$border-accent-blue-dark-mode: $brand-royal-60;

// Text
$text-white: $neutral-white;
$text-grey-dark: $neutral-grey-4;
$text-grey-light: $neutral-grey-2;
$text-black: $neutral-black;

$text-success-green-light-mode: $success-green-light-mode;
$text-success-green-dark-mode: $success-green-dark-mode;
$text-error-red-light-mode: $error-red-light-mode;
$text-error-red-dark-mode: $error-red-dark-mode;
$text-accent-blue-light-mode: $brand-eight-blue;
$text-accent-blue-dark-mode: $brand-royal-60;
$text-accent-orange: $brand-accent-orange;

// Link
$link-black: $neutral-black;
$link-white: $neutral-white;
$link-accent-blue-light-mode: $brand-eight-blue;
$link-accent-blue-dark-mode: $brand-royal-60;

// Event Colors
//$bfcm-soft-bg: #f5f3ee;
//$bfcm-gold-text: #9c6d26;
//$bfcm-gold-text-light: #f7c58d;
//$bfcm-floating-ecap-gradient: linear-gradient(0deg, rgba(63, 53, 33, 0.35) 0%, rgba(63, 53, 33, 0.35) 100%), linear-gradient(85deg, #917350 -3.75%, #cfac82 41.86%, #e5c192 64.84%, #6c5534 107.5%);
//$bfcm-gradient-bg: linear-gradient(90deg, #917350 0%, #cfac82 41%, #e5c192 61.66%, #6c5534 100%);
//$bfcm-gradient-bg-subdued: linear-gradient(0deg, rgba(63, 53, 33, 0.2) 0%, rgba(63, 53, 33, 0.2) 100%), linear-gradient(85deg, #917350 -3.75%, #cfac82 41.86%, #e5c192 64.84%, #6c5534 107.5%);
//$bfcm-gradient-text: linear-gradient(91deg, #fac78e -11.22%, #e5c192 99.24%);
//$bfcm-banner-gradient: linear-gradient(90deg, #917350 0%, #f8db9c 30.9%, #a88964 65.78%, #e5c192 78.22%, #6c5534 100%);
//$bfcm-promo-bg: rgba(208, 158, 91, 0.4);
